<template>
    <q-page class="flex flex-center">
        404 - Page Not Found
    </q-page>
</template>

<style>
</style>

<script>
export default {
    name: '404'
}
</script>
